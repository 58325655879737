<template>
  <div id="user-view">
    <v-form
      ref="formRef"
      @submit.prevent="saveData"
    >
      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="8"
            sm="6"
            class="pa-0"
          >
            <v-card-title>Haupt-Einstellungen</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.mainData.name"
                    label="Name"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.mainData.domain"
                    label="Domain"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Domain"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col
            cols="4"
            sm="6"
          >
            <div>
              <v-img
                width="120"
                src="@/assets/images/misc/tree-4.png"
                class="gamification-tree-4"
              ></v-img>
              <v-img
                width="80"
                src="@/assets/images/3d-characters/pose-2.png"
                class="gamification-john-pose-2"
              ></v-img>
              <v-img
                width="100"
                src="@/assets/images/misc/tree.png"
                class="gamification-tree"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col
          cols="12"
          :md="form.mainData.angabePrelayerBenutzen ? 6 : 12"
        >
          <v-card>
            <v-card-title>
              <v-switch
                v-model="form.mainData.angabePrelayerBenutzen"
                inset
                dense
                hide-details
              ></v-switch>
              Prelayer
            </v-card-title>

            <template v-if="form.mainData.angabePrelayerBenutzen">

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="form.prelayer.titel"
                      label="Titel"
                      type="text"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Titel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="form.prelayer.text"
                      label="Text"
                      auto-grow
                      outlined
                      dense
                      rows="3"
                      hide-details="auto"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="form.prelayer.datenschutzAnzeigen"
                      label="Datenschutzerklärung anzeigen"
                      hide-details
                      outlined
                      dense
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.prelayer.datenschutzAnzeigen">
                    <v-text-field
                      v-model="form.prelayer.datenschutzUrl"
                      label="Datenschutz URL"
                      type="text"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Datenschutz URL"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="form.prelayer.textButtonAkzeptieren"
                      label="Button-Text akzeptieren"
                      type="text"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Button-Text akzeptieren"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="form.prelayer.textButtonAblehnen"
                      label="Button-Text ablehnen"
                      type="text"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Button-Text ablehnen"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="form.prelayer.design"
                      hide-details="auto"
                      outlined
                      dense
                      :items="prelayerDesigns"
                      item-text="label"
                      item-value="value"
                      placeholder="Vorlagen"
                      label="Vorlagen"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-title>
                <v-switch
                  v-model="form.mainData.angabePrelayerStyleBenutzen"
                  inset
                  dense
                  hide-details
                ></v-switch>
                Style
              </v-card-title>

              <v-card-text v-if="form.mainData.angabePrelayerStyleBenutzen">
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="form.prelayerStyle.schriftart"
                    hide-details="auto"
                    outlined
                    dense
                    :items="fonts"
                    item-text="label"
                    item-value="value"
                    placeholder="Schriftart"
                    label="Schriftart"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                      v-model="form.prelayerStyle.farbeHintergrund"
                      hide-details
                      class="ma-0 pa-0 colorPicker"
                      outlined
                      dense
                      placeholder="Hintergrundfarbe"
                      label="Hintergrundfarbe"
                  >
                    <template v-slot:append>
                      <v-menu v-model="colorPickerMenu.farbeHintergrund" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="colorPickerThumbnail(form.prelayerStyle.farbeHintergrund)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="form.prelayerStyle.farbeHintergrund" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.prelayerStyle.farbeTitel"
                    hide-details
                    class="ma-0 pa-0 colorPicker"
                    outlined
                    dense
                    placeholder="Farbe Titel"
                    label="Farbe Titel"
                  >
                    <template v-slot:append>
                      <v-menu v-model="colorPickerMenu.farbeTitel" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="colorPickerThumbnail(form.prelayerStyle.farbeTitel)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="form.prelayerStyle.farbeTitel" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.prelayerStyle.farbeText"
                    hide-details
                    class="ma-0 pa-0 colorPicker"
                    outlined
                    dense
                    placeholder="Farbe Text"
                    label="Farbe Text"
                  >
                    <template v-slot:append>
                      <v-menu v-model="colorPickerMenu.farbeText" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="colorPickerThumbnail(form.prelayerStyle.farbeText)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="form.prelayerStyle.farbeText" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.prelayerStyle.farbeButtonAkzeptieren"
                    hide-details
                    class="ma-0 pa-0 colorPicker"
                    outlined
                    dense
                    placeholder="Farbe Button Akzeptieren"
                    label="Farbe Button Akzeptieren"
                  >
                    <template v-slot:append>
                      <v-menu v-model="colorPickerMenu.farbeButtonAkzeptieren" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="colorPickerThumbnail(form.prelayerStyle.farbeButtonAkzeptieren)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="form.prelayerStyle.farbeButtonAkzeptieren" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.prelayerStyle.farbeHintergrundButtonAkzeptieren"
                    hide-details
                    class="ma-0 pa-0 colorPicker"
                    outlined
                    dense
                    placeholder="Hintergrundfarbe Button Akzeptieren"
                    label="Hintergrundfarbe Button Akzeptieren"
                  >
                    <template v-slot:append>
                      <v-menu v-model="colorPickerMenu.farbeHintergrundButtonAkzeptieren" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="colorPickerThumbnail(form.prelayerStyle.farbeHintergrundButtonAkzeptieren)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="form.prelayerStyle.farbeHintergrundButtonAkzeptieren" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.prelayerStyle.farbeButtonAblehnen"
                    hide-details
                    class="ma-0 pa-0 colorPicker"
                    outlined
                    dense
                    placeholder="Farbe Button Ablehnen"
                    label="Farbe Button Ablehnen"
                  >
                    <template v-slot:append>
                      <v-menu v-model="colorPickerMenu.farbeButtonAblehnen" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="colorPickerThumbnail(form.prelayerStyle.farbeButtonAblehnen)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="form.prelayerStyle.farbeButtonAblehnen" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.prelayerStyle.farbeHintergrundButtonAblehnen"
                    hide-details
                    class="ma-0 pa-0 colorPicker"
                    outlined
                    dense
                    placeholder="Hintergrundfarbe Button Ablehnen"
                    label="Hintergrundfarbe Button Ablehnen"
                  >
                    <template v-slot:append>
                      <v-menu v-model="colorPickerMenu.farbeHintergrundButtonAblehnen" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="colorPickerThumbnail(form.prelayerStyle.farbeHintergrundButtonAblehnen)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="form.prelayerStyle.farbeHintergrundButtonAblehnen" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-card-text>
              <v-card-title>
                <v-switch
                  v-model="form.mainData.angabePrelayerPushIconBenutzen"
                  inset
                  dense
                  hide-details
                ></v-switch>
                Push-Icon
              </v-card-title>
              <v-card-text v-if="form.mainData.angabePrelayerPushIconBenutzen">
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-select
                      v-model="form.prelayerPushIcon.iconTyp"
                      hide-details="auto"
                      outlined
                      dense
                      :items="pushIconTypes"
                      item-text="label"
                      item-value="value"
                      placeholder="Typ"
                      label="Typ"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="form.prelayerPushIcon.farbeIcon"
                      hide-details
                      class="ma-0 pa-0 colorPicker"
                      outlined
                      dense
                      placeholder="Farbe Icon"
                      label="Farbe Icon"
                    >
                      <template v-slot:append>
                        <v-menu v-model="colorPickerMenu.farbeIcon" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                          <template v-slot:activator="{ on }">
                            <div :style="colorPickerThumbnail(form.prelayerPushIcon.farbeIcon)" v-on="on" />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker v-model="form.prelayerPushIcon.farbeIcon" flat />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="form.prelayerPushIcon.farbeIconHintergrund"
                      hide-details
                      class="ma-0 pa-0 colorPicker"
                      outlined
                      dense
                      placeholder="Hintergrundfarbe"
                      label="Hintergrundfarbe"
                    >
                      <template v-slot:append>
                        <v-menu v-model="colorPickerMenu.farbeIconHintergrund" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                          <template v-slot:activator="{ on }">
                            <div :style="colorPickerThumbnail(form.prelayerPushIcon.farbeIconHintergrund)" v-on="on" />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker v-model="form.prelayerPushIcon.farbeIconHintergrund" flat />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="form.mainData.angabePrelayerBenutzen"
        >
          <v-card
            title="Vorschau"
            class="fill-height"
          >
            <v-card-title>Vorschau</v-card-title>
            <v-card-text>
              <prelayer-preview
                :mainData="form.mainData"
                :prelayer="form.prelayer"
                :prelayerStyle="form.prelayerStyle"
                :prelayerPushIcon="form.prelayerPushIcon"
              ></prelayer-preview>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :md="form.mainData.angabeNachrichtBenutzen ? 6 : 12"
        >
          <v-card
            title="Wilkommensnachricht"
          >
            <v-card-title>
              <v-switch
                v-model="form.mainData.angabeNachrichtBenutzen"
                inset
                dense
                hide-details
              ></v-switch>
              Wilkommensnachricht
            </v-card-title>
            <v-card-text v-if="form.mainData.angabeNachrichtBenutzen">
              <div class="text-center" v-if="loading">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <template v-else>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="form.message.titel"
                      label="Titel"
                      type="text"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Titel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="form.message.text"
                      label="Text"
                      type="text"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="form.message.url"
                      label="URL"
                      type="text"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="https://..."
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row class="align-center">
                      <v-col :cols="imagePreviews.messageIcon != '' ? '10' : '12'" :md="imagePreviews.messageIcon != '' ? '10' : '12'">
                        <v-file-input
                          @change="previewImage('messageIcon')"
                          @click:clear="deleteImage('messageIcon')"
                          type="file"
                          v-model="messageIcon"
                          label="Icon"
                          outlined
                          dense
                          hide-details="auto"
                          placeholder="Icon"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="2" md="2" v-if="imagePreviews.messageIcon != ''">
                        <v-img
                          :src="imagePreviews.messageIcon"
                          :width="100"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row class="align-center">
                      <v-col :cols="imagePreviews.messageImage != '' ? '10' : '12'" :md="imagePreviews.messageImage != '' ? '10' : '12'">
                        <v-file-input
                          @change="previewImage('messageImage')"
                          @click:clear="deleteImage('messageImage')"
                          type="file"
                          v-model="messageImage"
                          label="Grafik"
                          outlined
                          dense
                          hide-details="auto"
                          placeholder="Grafik"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="2" md="2" v-if="imagePreviews.messageImage != ''">
                        <v-img
                          :src="imagePreviews.messageImage"
                          :width="100"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-card outlined>
                      <v-card-text>
                        <v-switch v-model="form.messageButtons[0].aktiv" class="mx-0" label="Button 1" hide-details></v-switch>
                        <template v-if="form.messageButtons[0].aktiv">
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-select
                              v-model="form.messageButtons[0].aktion"
                              hide-details="auto"
                              outlined
                              dense
                              :items="buttonActions"
                              item-text="label"
                              item-value="value"
                              placeholder="Aktion"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-text-field
                              v-model="form.messageButtons[0].url"
                              label="URL"
                              type="text"
                              outlined
                              dense
                              hide-details="auto"
                              placeholder="https://..."
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-row class="align-center">
                              <v-col :cols="imagePreviews.button1_icon != '' ? '10' : '12'" :md="imagePreviews.button1_icon != '' ? '10' : '12'">
                                <v-file-input
                                  @change="previewImage('button1_icon')"
                                  @click:clear="deleteImage('button1_icon')"
                                  type="file"
                                  v-model="button1_icon"
                                  label="Icon"
                                  outlined
                                  dense
                                  hide-details="auto"
                                  placeholder="Icon"
                                ></v-file-input>
                              </v-col>
                              <v-col cols="2" md="2" v-if="imagePreviews.button1_icon != ''">
                                <v-img
                                  :src="imagePreviews.button1_icon"
                                  :width="100"
                                ></v-img>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-card outlined>
                      <v-card-text>
                        <v-switch v-model="form.messageButtons[1].aktiv" class="mx-0" label="Button 2" hide-details></v-switch>
                        <template v-if="form.messageButtons[1].aktiv">
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-select
                              v-model="form.messageButtons[1].aktion"
                              hide-details="auto"
                              outlined
                              dense
                              :items="buttonActions"
                              item-text="label"
                              item-value="value"
                              placeholder="Aktion"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-text-field
                              v-model="form.messageButtons[1].url"
                              label="URL"
                              type="text"
                              outlined
                              dense
                              hide-details="auto"
                              placeholder="https://..."
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-row class="align-center">
                              <v-col :cols="imagePreviews.button2_icon != '' ? '10' : '12'" :md="imagePreviews.button2_icon != '' ? '10' : '12'">
                                <v-file-input
                                  @change="previewImage('button2_icon')"
                                  @click:clear="deleteImage('button2_icon')"
                                  type="file"
                                  v-model="button2_icon"
                                  label="Icon"
                                  outlined
                                  dense
                                  hide-details="auto"
                                  placeholder="Icon"
                                ></v-file-input>
                              </v-col>
                              <v-col cols="2" md="2" v-if="imagePreviews.button2_icon != ''">
                                <v-img
                                  :src="imagePreviews.button2_icon"
                                  :width="100"
                                ></v-img>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="form.mainData.angabeNachrichtBenutzen"
        >
          <v-card
            title="Vorschau"
            class="fill-height"
          >
            <v-card-title>Vorschau</v-card-title>
            <v-card-text>
              <push-notification-preview
                :title="form.message.titel"
                :text="form.message.text"
                :domain="form.mainData.domain"
                :icon="imagePreviews.messageIcon"
                :image="imagePreviews.messageImage"
                :button1="{...form.messageButtons[0],icon:imagePreviews.button1_icon}"
                :button2="{...form.messageButtons[1],icon:imagePreviews.button2_icon}"
              ></push-notification-preview>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-5">
      <v-col cols="12" md="12">
        <router-link :to="'/kanal'">
          <v-btn
            color="error"
            class="mx-2"
          >
            Zurück
          </v-btn>
        </router-link>
        <v-btn color="primary" type="submit">
          Speichern
        </v-btn>
        <v-btn
          type="reset"
          outlined
          class="mx-2"
        >
          Zurücksetzen
        </v-btn>
      </v-col>
    </v-row>
    </v-form>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance,nextTick} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiCalendarOutline,
  mdiClockOutline,
  mdiRefresh
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";
import {addAlpha, getVuetify} from "@core/utils";
import isBlob from 'is-blob';
import InlineDatePicker
  from "@/views/InlineDatePicker";
import PushNotificationPreview from "@/views/push-notification/Preview";
import PrelayerPreview from "@/views/prelayer/Preview";

export default {
  components: {
    PrelayerPreview,
    PushNotificationPreview,
    VueApexCharts: () => import('vue-apexcharts'),
    InlineDatePicker
  },
  data() {
    return {
      showPreview: true
    }
  },
  setup(props) {

    const userTab = ref(null)
    const tabs = ref([
      { icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
    ])

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    const formRef = ref(null)
    const form = ref({})
    const messageIcon = ref({});
    const messageImage = ref({});
    const button1_icon = ref({});
    const button2_icon = ref({});

    const imagePreviews = ref({
      messageIcon : '',
      messageImage : '',
      button1_icon : '',
      button2_icon : '',
    })

    const colorPickerThumbnail = (hexcodeFarbe) =>{
      if(!hexcodeFarbe){
        hexcodeFarbe = '#FF0000FF';
      }
      return {
        backgroundColor: hexcodeFarbe,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
    const colorPickerMenu = ref({
      farbeHintergrund : false,
      farbeTitel : false,
      farbeText : false,
      farbeButtonAkzeptieren : false,
      farbeHintergrundButtonAkzeptieren : false,
      farbeButtonAblehnen : false,
      farbeHintergrundButtonAblehnen : false,
      farbeIcon : false,
      farbeIconHintergrund : false,
    })

    const deletedImages = [];

    const previewImage = (key)=>{
      if(key == 'messageIcon'){
        imagePreviews.value[key] = isBlob(messageIcon.value) ? URL.createObjectURL(messageIcon.value) : '';
      }
      if(key == 'messageImage'){
        imagePreviews.value[key] = isBlob(messageImage.value) ? URL.createObjectURL(messageImage.value) : '';
      }
      if(key == 'button1_icon'){
        imagePreviews.value[key] = isBlob(button1_icon.value) ? URL.createObjectURL(button1_icon.value) : '';
      }
      if(key == 'button2_icon'){
        imagePreviews.value[key] = isBlob(button2_icon.value) ? URL.createObjectURL(button2_icon.value) : '';
      }
    }

    const deleteImage = (key) => {
      imagePreviews.value[key] = null;
      deletedImages.push(key);
    }

    const buttonActions = ref([
      {label:"Url öffnen", value:"bestaetigen"},
      {label:"Nachricht schließen", value:"abbrechen"},
      {label:"Nachricht zurückstellen", value:"zurueckstellen"}
    ]);

    const fonts = ref([
      {label:"Default", value:"Default"},
      {label:"Verdana", value:"Verdana"},
      {label:"Tahoma", value:"Tahoma"},
      {label:"Arial", value:"Arial"}
    ]);

    const pushIconTypes = ref([
      {label:"Klassisch", value:"klassisch"},
      {label:"Modern", value:"modern"},
    ]);

    const prelayerDesigns = ref([
      {label:"Default", value:"default"},
      {label:"Modern", value:"modern"},
    ]);

    form.value = {
      mainData : {
        idNipKanal : router.currentRoute.params.idNipKanal,
        name : '',
        domain : '',
        angabePrelayerBenutzen : false,
        angabePrelayerStyleBenutzen : false,
        angabePrelayerPushIconBenutzen : false,
        angabeNachrichtBenutzen : false,
      },
      prelayer : {
        titel:"",
        text:"",
        textButtonAkzeptieren:"",
        textButtonAblehnen:"",
        datenschutzAnzeigen:"",
        datenschutzUrl:"",
        design:"default"
      },
      prelayerStyle : {
        schriftart:"Default",
        farbeHintergrund:"",
        farbeTitel:"",
        farbeText:"",
        farbeButtonAkzeptieren:"",
        farbeButtonAblehnen:"",
        farbeHintergrundButtonAkzeptieren:"",
        farbeHintergrundButtonAblehnen:""
      },
      prelayerPushIcon : {
        iconTyp:"klassisch",
        farbeIcon: "",
        farbeIconHintergrund: "",
      },
      message : {
        titel:"",
        text:"",
        url:"",
        icon:"",
        grafik:""
      },
      messageButtons : [
        {key:0,url:'',icon:'',aktion:'',aktiv:false},
        {key:1,url:'',icon:'',aktion:'',aktiv:false},
      ],
      aktion: (router.currentRoute.params.idNipKanal !== undefined ? 'editChannelCheck' : 'createChannelCheck'),
    };
    const loading = ref(true)

    onMounted(()=>{

      if(form.value.aktion == 'editChannelCheck'){
        axios.post('/api/kanal/', {
          aktion: 'editChannel',
          mainData:{
            idNipKanal : router.currentRoute.params.idNipKanal,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {

              form.value.mainData = response.data.channel;
              form.value.prelayer = response.data.channel.prelayer;
              form.value.prelayerStyle = response.data.channel.prelayerStyle;
              form.value.prelayerPushIcon = response.data.channel.prelayerPushIcon;

              console.log(response.data.channel)

              form.value.message = response.data.channel.message;
              form.value.messageButtons = response.data.channel.messageButtons;

              for (const [key, item] of Object.entries(response.data.channel.images)) {
                imagePreviews.value[key] = item.url;
              }

            } else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;
          })
      }
      else{
        loading.value = false;
      }
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid) return
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion',form.value.aktion);
      fd.append('mainData[idNipKanal]',form.value.mainData.idNipKanal);
      fd.append('mainData[name]',form.value.mainData.name);
      fd.append('mainData[domain]',form.value.mainData.domain);
      fd.append('mainData[angabePrelayerBenutzen]',form.value.mainData.angabePrelayerBenutzen);
      fd.append('mainData[angabePrelayerStyleBenutzen]',form.value.mainData.angabePrelayerStyleBenutzen);
      fd.append('mainData[angabePrelayerPushIconBenutzen]',form.value.mainData.angabePrelayerPushIconBenutzen);
      fd.append('mainData[angabeNachrichtBenutzen]',form.value.mainData.angabeNachrichtBenutzen);

      fd.append('prelayer[titel]',form.value.prelayer.titel || '');
      fd.append('prelayer[text]',form.value.prelayer.text || '');
      fd.append('prelayer[textButtonAkzeptieren]',form.value.prelayer.textButtonAkzeptieren || '');
      fd.append('prelayer[textButtonAblehnen]',form.value.prelayer.textButtonAblehnen || '');
      fd.append('prelayer[datenschutzAnzeigen]',form.value.prelayer.datenschutzAnzeigen || false);
      fd.append('prelayer[datenschutzUrl]',form.value.prelayer.datenschutzUrl || '');
      fd.append('prelayer[design]',form.value.prelayer.design || 'default');

      fd.append('prelayerStyle[schriftart]',form.value.prelayerStyle.schriftart || '0');
      fd.append('prelayerStyle[farbeHintergrund]',form.value.prelayerStyle.farbeHintergrund || '');
      fd.append('prelayerStyle[farbeTitel]',form.value.prelayerStyle.farbeTitel || '');
      fd.append('prelayerStyle[farbeText]',form.value.prelayerStyle.farbeText || '');
      fd.append('prelayerStyle[farbeButtonAkzeptieren]',form.value.prelayerStyle.farbeButtonAkzeptieren || '');
      fd.append('prelayerStyle[farbeButtonAblehnen]',form.value.prelayerStyle.farbeButtonAblehnen || '');
      fd.append('prelayerStyle[farbeHintergrundButtonAkzeptieren]',form.value.prelayerStyle.farbeHintergrundButtonAkzeptieren || '');
      fd.append('prelayerStyle[farbeHintergrundButtonAblehnen]',form.value.prelayerStyle.farbeHintergrundButtonAblehnen || '');

      fd.append('prelayerPushIcon[iconTyp]',form.value.prelayerPushIcon.iconTyp || '');
      fd.append('prelayerPushIcon[farbeIcon]',form.value.prelayerPushIcon.farbeIcon || '');
      fd.append('prelayerPushIcon[farbeIconHintergrund]',form.value.prelayerPushIcon.farbeIconHintergrund || '');

      fd.append('message[titel]',form.value.message.titel || '');
      fd.append('message[text]',form.value.message.text || '');
      fd.append('message[url]',form.value.message.url || '');

      form.value.messageButtons.forEach((item,key) => {
        fd.append(`messageButtons[${key}][url]`,item.url);
        fd.append(`messageButtons[${key}][icon]`,item.icon);
        fd.append(`messageButtons[${key}][aktion]`,item.aktion);
        fd.append(`messageButtons[${key}][aktiv]`,item.aktiv);
      })

      deletedImages.forEach((value,key) => {
        fd.append(`deletedImages[${key}]`,value);
      })

      if(isBlob(messageIcon.value)){
        fd.append('images[messageIcon]',messageIcon.value,messageIcon.value.name);
      }
      if(isBlob(messageImage.value)){
        fd.append('images[messageImage]',messageImage.value,messageImage.value.name);
      }
      if(isBlob(button1_icon.value)){
        fd.append('images[button1_icon]',button1_icon.value,button1_icon.value.name);
      }
      if(isBlob(button2_icon.value)){
        fd.append('images[button2_icon]',button2_icon.value,button2_icon.value.name);
      }

      axios
        .post('/api/kanal/',fd)
        .then(response => {
          if(response.data.valid === true){
            router.push('/kanal',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    const updateMainData  = (key,value) => {
      nextTick(() => {
        form.value.mainData[key] = value;
      })
    }

    return {
      userTab,
      tabs,
      form,
      formRef,
      messageIcon,
      messageImage,
      button1_icon,
      button2_icon,
      saveData,
      loading,
      buttonActions,
      previewImage,
      colorPickerMenu,
      pushIconTypes,
      deleteImage,
      imagePreviews,
      colorPickerThumbnail,
      fonts,
      updateMainData,
      prelayerDesigns,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiCalendarOutline,
        mdiClockOutline,
        mdiRefresh,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs{
  display: none;
}
#user-tabs-content{
  margin:0 !important;
}

#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}

.v-card__subtitle, .v-card__text #chart-sales-overview{
  font-size: unset;
}

.v-file-input {
  flex-direction: row-reverse;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-input__append-outer {
  white-space: nowrap;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

.refreshIcon {
  position: absolute !important;
  right: 30px;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.colorPicker{
  .v-input__append-inner{
    margin-top:5px !important;
  }
}
</style>
